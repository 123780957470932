import { render, staticRenderFns } from "./wx-user.vue?vue&type=template&id=6b5e3697&scoped=true"
import script from "./wx-user.vue?vue&type=script&lang=js"
export * from "./wx-user.vue?vue&type=script&lang=js"
import style0 from "./wx-user.vue?vue&type=style&index=0&id=6b5e3697&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b5e3697",
  null
  
)

export default component.exports